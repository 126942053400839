import { ApolloProvider } from "@apollo/client";
import { useApollo } from "@shared/lib/Apollo/apollo-client";
import { AppProps } from "next/app";
import Head from "next/head";
import React from "react";
import "@ui/styles/globals.css";
import { Modal } from "@ui/components/modal/modal";
import { getModalRef } from "@shared/services/modal-service";
import { UiContextProvider, UiContextType } from "@uxf/ui/context";
import { ICON_SPRITE, ICONS_VERSION } from "@config/icons-config";
import { ICONS } from "@config/icons";
import { twScreens } from "@config/tw-tokens/tw-screens";

const UI_PROVIDER_CONFIG: UiContextType = {
    icon: { spriteFilePath: `${ICON_SPRITE}?v=${ICONS_VERSION}`, iconsConfig: ICONS },
    rasterImage: { breakpoints: twScreens },
};

function UxfApp(props: AppProps) {
    const apollo = useApollo(props.pageProps);

    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                />
            </Head>
            <ApolloProvider client={apollo}>
                <UiContextProvider value={UI_PROVIDER_CONFIG}>
                    <props.Component {...props.pageProps} />
                    <Modal ref={getModalRef()} />
                </UiContextProvider>
            </ApolloProvider>
        </>
    );
}

export default UxfApp;
