import dynamic from "next/dynamic";
import { forwardRef, ReactNode, useCallback, useImperativeHandle, useRef, useState } from "react";
import type { ModalDialogProps } from "./modal-dialog";

const Content = dynamic<ModalDialogProps>(() => import("./modal-dialog").then((component) => component.ModalDialog), {
    ssr: false,
});

export interface ModalProps {
    children: ReactNode;
    onClose?: () => void;
    width?: ModalDialogProps["width"];
}

export interface ModalRef {
    close: () => void;
    open: (modal: ModalProps) => void;
}

export const Modal = forwardRef<ModalRef>((props, ref) => {
    const [modal, setModal] = useState<ModalProps | null>();

    const open = useCallback((newModal: ModalProps) => setModal(newModal), []);

    const close = useCallback(() => setModal(null), []);

    const innerRef = useRef<ModalRef>({
        close,
        open,
    });

    useImperativeHandle(
        ref,
        () => ({
            close: innerRef.current.close,
            open: innerRef.current.open,
        }),
        [],
    );

    const onClose = useCallback(() => {
        setModal((prev) => {
            if (prev?.onClose) {
                prev.onClose();
            }
            return null;
        });
    }, []);

    return modal ? (
        <Content open={!!modal} onClose={onClose} width={modal.width}>
            {modal.children}
        </Content>
    ) : null;
});

Modal.displayName = "Modal";
