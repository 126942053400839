// this file is generated automatically, do not change anything manually in the contents of this file

export const ICONS = {
    "calendar": { w: 448, h: 512 },
    "camera": { w: 512, h: 512 },
    "caretDown": { w: 320, h: 512 },
    "clock": { w: 512, h: 512 },
    "bars": { w: 448, h: 512 },
    "check": { w: 512, h: 512 },
    "chevronDown": { w: 512, h: 512 },
    "chevronLeft": { w: 384, h: 512 },
    "chevronsLeft": { w: 512, h: 512 },
    "chevronRight": { w: 384, h: 512 },
    "chevronsRight": { w: 512, h: 512 },
    "chevronUp": { w: 512, h: 512 },
    "user": { w: 448, h: 512 },
    "xmarkLarge": { w: 448, h: 512 },
    "logo": { w: 40, h: 40 },
    "cross": { w: 34, h: 34 },
} as const;

export type IconsType = keyof typeof ICONS;

declare module "@uxf/ui/icon/theme" {
    interface IconsSet {
        "calendar": true;
        "camera": true;
        "caretDown": true;
        "clock": true;
        "bars": true;
        "check": true;
        "chevronDown": true;
        "chevronLeft": true;
        "chevronsLeft": true;
        "chevronRight": true;
        "chevronsRight": true;
        "chevronUp": true;
        "user": true;
        "xmarkLarge": true;
        "logo": true;
        "cross": true;
    }
}
