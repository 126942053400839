import { ModalProps, ModalRef } from "@ui/components/modal/modal";
import { createRef } from "react";

const modalRef = createRef<ModalRef>();

export function getModalRef() {
    return modalRef;
}

export function openModal(modal: ModalProps) {
    if (modalRef.current) {
        modalRef.current.open(modal);
    }
}

export function closeModal() {
    if (modalRef.current) {
        modalRef.current.close();
    }
}
